import React from "react";
import { observer } from "mobx-react";
import { NavigationCircleIcon, NavigationCirclePinIcon } from "@wingmate/graphics";
import { Flex } from "@wingmate/toolkit";
import { useStore } from "../../../../hooks/useStore";

import "./NavigationPin.scss";

export const NavigationPin = observer(() => {
  const { navigationStore } = useStore();
  const { isPinned } = navigationStore;

  const pinSideNavigation = () => {
    navigationStore.setIsPinned(true);
  };

  const unpinSideNavigation = () => {
    navigationStore.setIsPinned(false);
  };

  return (
    <Flex className="NavigationPin" justify="center" align="center" vertical>
      {isPinned ? (
        <NavigationCirclePinIcon data-testid="NavigationCirclePinIcon" onClick={unpinSideNavigation} />
      ) : (
        <NavigationCircleIcon data-testid="NavigationCircleIcon" onClick={pinSideNavigation} />
      )}
    </Flex>
  );
});

export default NavigationPin;
