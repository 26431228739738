import React from "react";
import PropTypes from "prop-types";
import { Address } from "./Address/Address";
import { SearchField } from "wm-ui-toolkit";

import "./AddressField.scss";

const DELAY = 500;

export class AddressField extends React.Component {
  static defaultProps = {
    defaultValue: "",
  };

  static propTypes = {
    defaultValue: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      places: [],
      value: props.defaultValue || props.value || "",
    };

    this.autocomplete = null;
    this.geocoder = null;
  }

  componentDidMount() {
    if (window.google) {
      this.autocomplete = new window.google.maps.places.AutocompleteService();
      this.geocoder = new window.google.maps.Geocoder();
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    this.updateValueStateIfPropChanged(value, prevProps.value);
  }

  updateValueStateIfPropChanged = (newValue, previousValue) => {
    if (newValue !== previousValue) {
      this.setState({ value: newValue });
    }
  };

  onChange = (address, geocodedInfo) => {
    const { id, onChange } = this.props;
    this.setState({ value: address });

    if (onChange) {
      if (id) {
        onChange({
          id,
          value: address,
          geocodedInfo,
        });
      } else {
        onChange({ value: address, geocodedInfo });
      }
    }
  };

  getGeocodedInfo = async (payload) => {
    let address = "";
    let latitude = null;
    let longitude = null;
    try {
      await this.geocoder.geocode(payload, (results, status) => {
        if (status === "OK") {
          latitude = results[0].geometry.location.lat();
          longitude = results[0].geometry.location.lng();
          address = results[0].formatted_address;
        }
      });
    } catch {
      return { address, latitude, longitude };
    }
    return { address, latitude, longitude };
  };

  getPredictions = async (searchTerm) => {
    if (!searchTerm) {
      this.onChange(searchTerm, { address: "", lat: null, lng: null });
      this.setState({ places: [] });
      return;
    }
    const geocodedInfo = await this.getGeocodedInfo({ address: searchTerm });
    this.onChange(searchTerm, geocodedInfo);
    try {
      const response = await this.autocomplete.getPlacePredictions({
        input: searchTerm,
        types: ["address"],
      });
      const formattedPlaces = response.predictions.map((place) => {
        return {
          label: place.description,
          matchedSubstrings: place.matched_substrings,
        };
      });
      this.setState({ places: formattedPlaces });
    } catch (error) {
      this.onChange(searchTerm, geocodedInfo);
      this.setState({ places: [] });
    }
  };

  renderOption = (place) => {
    const { label, matchedSubstrings } = place;
    return <Address originalString={label} offsets={matchedSubstrings} />;
  };

  render() {
    const { placeholder } = this.props;
    const { places, value } = this.state;

    return (
      <SearchField
        className="AddressField"
        defaultSearchTerm={value}
        placeholder={placeholder}
        options={places}
        renderOption={this.renderOption}
        delay={DELAY}
        onSearch={this.getPredictions}
        showSearchIcon={false}
      />
    );
  }
}

export default AddressField;
