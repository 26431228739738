export const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
export const integrationBaseUrl =
  process.env.REACT_APP_INTEGRATION_SERVICE_BASE_URL;

export const duplicatesBaseUrl =
  process.env.REACT_APP_DUPLICATES_SERVICE_BASE_URL;

export const mapsBaseUrl = process.env.REACT_APP_MAPS_SERVICE_BASE_URL;

export const endpoint = (action) => {
  return `${baseUrl}/${action}`;
};

export const integrationEndpoint = (action) => {
  return `${integrationBaseUrl}/${action}`;
};

export const duplicatesEndpoint = (action) => {
  return `${duplicatesBaseUrl}/${action}`;
};

export const mapsEndpoint = (action) => {
  return `${mapsBaseUrl}/${action}`;
};
