import { duplicatesEndpoint } from "../../utils/endpoint";
import { axiosGet, axiosPost } from "../axiosRequests";

const GET_DUPLICATES_ENDPOINT = (leadId) =>
  duplicatesEndpoint(`duplicates?leadId=${leadId}`);

const CHECK_DUPLICATES_ENDPOINT = duplicatesEndpoint("checks");

export async function getDuplicates(token, leadId) {
  const response = await axiosGet(
    GET_DUPLICATES_ENDPOINT(leadId),
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
  return response.data;
}

export async function checkForDuplicates(token, payload) {
  const response = await axiosPost(CHECK_DUPLICATES_ENDPOINT, payload, {
    Authorization: `Bearer ${token}`,
  });
  return response.data;
}

export default { getDuplicates };
