import React from "react";
import { action, computed, makeObservable, observable } from "mobx";
import {
  FunnelViewIcon,
  ListViewIcon,
  MapViewIcon,
  TableViewIcon,
} from "wm-graphics";

const DASHBOARD_VIEW_STORAGE_KEY = "dashboardView";

const DEFAULT_VIEW_ID = "funnel";

const VIEW_URL_SEARCH_PARAM_NAME = "view";

const VIEWS = [
  {
    icon: <FunnelViewIcon width={16} height={16} />,
    id: "funnel",
    name: "Funnel",
  },
  {
    icon: <ListViewIcon width={16} height={16} />,
    id: "list",
    name: "List",
  },
  {
    icon: <MapViewIcon width={16} height={16} />,
    id: "map",
    name: "Map",
  },
  {
    icon: <TableViewIcon width={16} height={16} />,
    id: "table",
    name: "Table",
  },
];

export class ViewStore {
  rootStore;

  @observable view;

  constructor(rootStore) {
    makeObservable(this);

    this.rootStore = rootStore;

    this.view = this.getInitialView();
  }

  @computed get storageKey() {
    const { context } = this.rootStore;

    if (context && context.currentUser && context.campaign) {
      return `ViewStore/u${context.currentUser.id}/c${context.campaign.id}/${DASHBOARD_VIEW_STORAGE_KEY}`;
    } else {
      return DASHBOARD_VIEW_STORAGE_KEY;
    }
  }

  @action
  getInitialView() {
    let view;
    const params = new URLSearchParams(document.location.search.substring(1));
    const viewParam = params.get(VIEW_URL_SEARCH_PARAM_NAME);

    view = VIEWS.find((viewSetting) => viewSetting.id === viewParam);

    if (view) {
      return view;
    }
    const viewIdFromStorage = JSON.parse(this.getFromLocalStorage());
    view = VIEWS.find((viewSetting) => viewSetting.id === viewIdFromStorage);
    if (view) {
      return view;
    }
    return VIEWS.find((viewSetting) => viewSetting.id === DEFAULT_VIEW_ID);
  }

  @action
  setView(viewId) {
    let newView = VIEWS.find((view) => view.id === viewId);

    if (!newView) {
      newView = VIEWS[0];
    }

    this.view = newView;
    this.setToLocalStorage(newView.id);

    this.rootStore.filtersStore.setBrowserUrl();
  }

  @computed get viewOptions() {
    const viewOptions = VIEWS.filter(
      (viewOption) => viewOption.id !== this.view.id
    );

    return viewOptions;
  }

  setToLocalStorage(view) {
    localStorage.setItem(this.storageKey, JSON.stringify(view));
  }

  getFromLocalStorage() {
    return localStorage.getItem(this.storageKey);
  }
}

export default ViewStore;
