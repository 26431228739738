import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Dropdown, Text } from "@wingmate/toolkit";
import {
  authStorePropTypes,
  rootStorePropTypes,
} from "../../../proptypes/stores";
import { AppContext } from "../../../context";
import { withRouter } from "../../../utils/withRouter";

import "./ContractSelector.scss";

@withRouter
@inject("authStore", "rootStore")
@observer
export class ContractSelector extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    authStore: authStorePropTypes,
    contracts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        campaign: PropTypes.object,
        active: PropTypes.bool,
      })
    ),
    defaultContract: PropTypes.shape({
      id: PropTypes.string,
      active: PropTypes.bool,
      campaign: PropTypes.object,
    }),
    navigate: PropTypes.func,
    rootStore: rootStorePropTypes,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      visibleContracts: props.contracts,
    };
  }

  onSearchChange = (input) => {
    const { rootStore } = this.props;
    const { contractStore } = rootStore;
    const { contracts } = contractStore;

    let visibleContracts;

    if (input) {
      const searchTerm = input.value;
      visibleContracts = this.searchContracts(searchTerm);
    } else {
      visibleContracts = contracts;
    }
    this.setState({ visibleContracts });
  };

  searchContracts(searchTerm) {
    const { contracts } = this.props;
    return contracts.filter((contract) =>
      this.containsSearchTerm(contract, searchTerm)
    );
  }

  containsSearchTerm(contract, searchTerm) {
    return contract.attributes.campaign.attributes.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  }

  onSelectedChange = async (menuPayload) => {
    const { rootStore } = this.props;
    const { contractStore } = rootStore;

    const { key: contractId } = menuPayload;

    const selectedContract = contractStore.getActiveContract();

    const payload = { contract: { active: true } };

    if (contractId !== selectedContract.id) {
      await contractStore.updateContract(contractId, payload);

      window.location.replace("/");
    }
  };

  renderContractItems = () => {
    const { visibleContracts } = this.state;

    return visibleContracts.map((contract) => {
      const { attributes } = contract;
      const { campaign } = attributes;
      const { attributes: campaignAttributes } = campaign;
      const { logoUrl, name } = campaignAttributes;

      return {
        key: contract.id,
        label: <Text type="P">{name}</Text>,
        icon: <div className="dropdown__logo">{logoUrl && <img src={logoUrl} alt="" />}</div>,
      }
    });
  }

  render() {
    const { contracts, rootStore } = this.props;
    const { contractStore } = rootStore;

    let selectedContract = contractStore.getActiveContract();

    let name;
    if (selectedContract) {
      const { attributes } = selectedContract;
      const { campaign } = attributes;
      name = campaign.attributes.name;
    }

    const showDropdown = contracts.length > 1;

    return (
      <div className="ContractSelector" data-testid="ContractSelector">
        {selectedContract && (
          <>
            {showDropdown ? (
              <Dropdown
                rootClassName="ContractSelector__dropdown"
                menu={{ items: this.renderContractItems(), onClick: this.onSelectedChange }}
                id="contractSelector"
                onSearch={this.onSearchChange}
                trigger={["click"]}
                searchProps={{
                  placeholder: name,
                }}
              />
            ) : (
              <Text className="ContractSelector__selected" type="H6">
                {name}
              </Text>
            )}
          </>
        )}
      </div>
    );
  }
}

export default ContractSelector;
