import React from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../../context/AppProvider";
import { Section } from "../../Section/Section";
import { inject, observer } from "mobx-react";
import { rootStorePropTypes } from "../../../proptypes/stores";
import moment from "moment";
import { Text } from "wm-ui-toolkit";
import { withTranslations } from "../../../utils/withTranslations";
import { ReactComponent as ScoreCardIcon } from "../../../assets/navigation/scorecard-headericon.svg";
import { ScoreCardChart } from "../../../common/ScoreCardChart/ScoreCardChart";
import "./CurrentScoreCard.scss";

@withTranslations
@inject("rootStore")
@observer
export class CurrentScoreCard extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    rootStore: rootStorePropTypes,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.scoreCardRef = React.createRef();
  }

  async componentDidMount() {
    const { rootStore } = this.props;
    const { performancesStore, tasksStore } = rootStore;
    const { currentUser } = this.context;

    await tasksStore.getTasksAsync();
    await performancesStore.getPerformancesAsync(currentUser.id);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleMouseClick = async () => {
    const { visible } = this.state;

    if (!visible) {
      this.setState({ visible: true });

      const { rootStore } = this.props;
      const { performancesStore, tasksStore } = rootStore;
      const { currentUser } = this.context;

      await tasksStore.getTasksAsync();
      await performancesStore.getPerformancesAsync(currentUser.id);

      document.addEventListener("mousedown", this.handleClickOutside);
    } else {
      this.setState({ visible: false });
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
  };

  handleClickOutside = (event) => {
    const { visible } = this.state;
    if (
      this.scoreCardRef &&
      !this.scoreCardRef.current.contains(event.target)
    ) {
      if (visible) {
        this.setState({ visible: false });
      }
    }
  };

  renderDateRange = () => {
    const { rootStore } = this.props;
    const { performancesStore } = rootStore;
    const { performances } = performancesStore;
    let firstPerformance;
    let dateRange;
    if (performances.length) {
      firstPerformance = performances[0].attributes;
      const fromDate = moment(firstPerformance.startDate).format("MMM.DD");
      const toDate = moment(firstPerformance.endDate).format("MMM.DD, YYYY");
      dateRange = `${fromDate}-${toDate}`;
    }

    return (
      <Text className="dateRange" type="H6">
        {dateRange}
      </Text>
    );
  };

  renderUserScoreCard = () => {
    const { rootStore, t } = this.props;
    const { performancesStore } = rootStore;

    return (
      <Section className="CurrentScoreCard__scoreCard">
        <div className="CurrentScoreCard__topHeader">
          <Text type="H6">{t("common:layouts.targetTitle")}</Text>
          {this.renderDateRange()}
        </div>
        <div className="scoreCard__chartContainer">
          <ScoreCardChart
            performances={performancesStore.currentUserPerformances}
          />
        </div>
      </Section>
    );
  };

  render() {
    const { visible } = this.state;

    return (
      <div
        className="CurrentScoreCard"
        data-testid="CurrentScoreCard"
        ref={this.scoreCardRef}
        onClick={this.handleMouseClick}
        onKeyDown={this.handleMouseClick}
        role="button"
        tabIndex="0"
      >
        <ScoreCardIcon width="35px" height="35px" />
        {visible && this.renderUserScoreCard()}
      </div>
    );
  }
}

export default CurrentScoreCard;
