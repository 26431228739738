import classNames from "classnames";
import React from "react";
import "./Section.scss";

interface ISectionProps {
  children?: React.ReactNode;
  className?: string;
}

export const Section: React.FC<ISectionProps> = ({ children, className }) => {
  const sectionClass = classNames("Section", className);

  return (
    <div data-testid="section" className={sectionClass}>
      {children}
    </div>
  );
};

Section.defaultProps = {
  children: null,
  className: "",
};

export default Section;
