import { action, computed, makeObservable, observable } from "mobx";
import { buildParams } from "../utils/commons";
import { endpoint } from "../utils/endpoint";

const LEADS_ENDPOINT = (format = "", filtersEncoded = "") =>
  endpoint(
    `app/v1.0/leads.${format}${
      filtersEncoded !== "" ? `?${filtersEncoded}` : ""
    }`
  );

const FILTER_SUBMIT_DELAY = 1000;

export class FiltersStore {
  rootStore;

  @observable filters;

  delayTimeout;

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.filters = {};
  }

  @computed get filterCount() {
    const filterCount = Object.keys(this.filters).reduce((count, key) => {
      const filterValue = this.filters[key];

      if (Array.isArray(filterValue)) {
        return count + filterValue.length;
      } else {
        return count + 1;
      }
    }, 0);
    return filterCount;
  }

  @computed get uriEncodedFilters() {
    const { viewStore } = this.rootStore;

    const urlFilters = {
      view: viewStore.view.id,
    };

    const urlString = buildParams({ ...urlFilters, ...this.filters });

    return urlString;
  }

  getCsvFilters() {
    const { mapStore, viewStore } = this.rootStore;

    if (viewStore.view.id === "map") {
      const { latitude, longitude, radius } = mapStore;

      const mapFilters = {
        map: true,
        latitude: latitude,
        longitude: longitude,
        radius: radius,
      };

      const mapUrl = buildParams(mapFilters);
      const urlString = `${this.uriEncodedFilters}&${mapUrl}`;
      return urlString;
    } else {
      return this.uriEncodedFilters;
    }
  }

  @computed get leadExportUrl() {
    const csvFilters = this.getCsvFilters();

    return LEADS_ENDPOINT("csv", csvFilters);
  }

  setBrowserUrl() {
    const newUrl = `${window.location.pathname}?${this.uriEncodedFilters}`;
    window.history.pushState("", null, newUrl);
  }

  @action
  setFilter(newFilters) {
    this.rootStore.funnelStore.setIsInitialized(false);
    this.rootStore.listLeadsStore.setIsInitialized(false);
    clearTimeout(this.delayTimeout);
    this.delayTimeout = setTimeout(
      () => this.updateLeadsFromFilters(newFilters),
      FILTER_SUBMIT_DELAY
    );
  }

  @action
  updateLeadsFromFilters(newFilters) {
    this.filters = newFilters;
    this.reloadDashboards();
    this.setBrowserUrl();
  }

  @action
  clearFilters() {
    this.rootStore.funnelStore.setIsInitialized(false);
    this.rootStore.listLeadsStore.setIsInitialized(false);
    this.filters = {};
    this.setBrowserUrl();
    this.reloadDashboards();
    this.reloadLeads();
  }

  reloadDashboards() {
    this.rootStore.funnelStore.retrieveFunnelAsync(this.filters);
    this.rootStore.listLeadsStore.retrieveLeadsAsync(this.filters);
    this.rootStore.mapStore.retrieveFilteredMapLeads(this.filters);
  }

  reloadLeads() {
    this.rootStore.mapStore.retrieveFilteredMapLeads(this.filters);
  }
}

export default FiltersStore;
