import { action, computed, makeObservable, observable, override } from "mobx";
import { BaseStore } from "./BaseStore";

export class ListLeadsStore extends BaseStore {
  rootStore;

  @observable leads;

  @observable page;

  @observable activePipelineAmount;

  @observable activePipelineCount;

  @observable activePipelineTotalAmount;

  @observable activePipelineTotalCount;

  @observable totalPipelineAmount;

  @observable totalPipelineCount;

  @observable totalPipelineTotalAmount;

  @observable totalPipelineTotalCount;

  @observable isLoading;

  @observable isInitialized = false;

  constructor(rootStore) {
    super();

    makeObservable(this);
    this.rootStore = rootStore;
    this.leads = [];
    this.activePipelineAmount = 0;
    this.activePipelineCount = 0;
    this.activePipelineTotalAmount = 0;
    this.activePipelineTotalCount = 0;
    this.totalPipelineAmount = 0;
    this.totalPipelineCount = 0;
    this.totalPipelineTotalAmount = 0;
    this.totalPipelineTotalCount = 0;
    this.page = {};
    this.leadsWithDetails = {};
  }

  @override
  clear() {
    this.setLeads([]);
    this.setPage({});

    this.setActivePipelineAmount(0);
    this.setActivePipelineCount(0);
    this.setActivePipelineTotalAmount(0);
    this.setActivePipelineTotalCount(0);
    this.setTotalPipelineAmount(0);
    this.setTotalPipelineCount(0);
    this.setTotalPipelineTotalAmount(0);
    this.setTotalPipelineTotalCount(0);
    this.setIsInitialized(false);
  }

  @computed get leadStats() {
    return {
      activePipelineAmount: this.activePipelineAmount,
      activePipelineCount: this.activePipelineCount,
      activePipelineTotalAmount: this.activePipelineTotalAmount,
      activePipelineTotalCount: this.activePipelineTotalCount,
      totalPipelineAmount: this.totalPipelineAmount,
      totalPipelineCount: this.totalPipelineCount,
      totalPipelineTotalAmount: this.totalPipelineTotalAmount,
      totalPipelineTotalCount: this.totalPipelineTotalCount,
    };
  }

  @computed get currentLeadsCount() {
    return this.leads ? this.leads.length : 0;
  }

  @computed get currentLeadsAmount() {
    return this.leads.reduce(
      (sum, lead) =>
        lead.attributes.amount ? sum + lead.attributes.amount : sum,
      0
    );
  }

  @action
  setIsInitialized(initialized) {
    this.isInitialized = initialized;
  }

  @action
  setIsLoading(loading) {
    this.isLoading = loading;
  }

  @action
  setLeads(leads) {
    this.leads = leads;
  }

  @action
  setLead(lead) {
    const leadIndex = this.findLeadIndex(lead.id);
    if (leadIndex !== -1) {
      this.leads[leadIndex] = lead;
      this.leadsWithDetails[lead.id] = true;
    }
  }

  @action
  setPage(page) {
    this.page = page;
  }

  @action
  setActivePipelineAmount(activePipelineAmount){
    this.activePipelineAmount = activePipelineAmount;
  }

  @action
  setActivePipelineCount(activePipelineCount){
    this.activePipelineCount = activePipelineCount;
  }

  @action
  setActivePipelineTotalAmount(activePipelineTotalAmount){
    this.activePipelineTotalAmount = activePipelineTotalAmount;
  }

  @action
  setActivePipelineTotalCount(activePipelineTotalCount){
    this.activePipelineTotalCount = activePipelineTotalCount;
  }

  @action
  setTotalPipelineAmount(totalPipelineAmount){
    this.totalPipelineAmount = totalPipelineAmount;
  }

  @action
  setTotalPipelineCount(totalPipelineCount){
    this.totalPipelineCount = totalPipelineCount;
  }

  @action
  setTotalPipelineTotalAmount(totalPipelineTotalAmount){
    this.totalPipelineTotalAmount = totalPipelineTotalAmount;
  }

  @action
  setTotalPipelineTotalCount(totalPipelineTotalCount){
    this.totalPipelineTotalCount = totalPipelineTotalCount;
  }

  findLead(leadId) {
    const leadFound = this.leads.find(
      (lead) => lead.id.toString() === leadId.toString()
    );

    return leadFound;
  }

  findLeadIndex(leadId) {
    return this.leads.findIndex((lead) => lead.id === leadId);
  }

  @action
  saveLead(leadId, newLead) {
    const index = this.findLeadIndex(leadId);
    this.leads[index] = newLead;
  }

  @action
  async updateLead(leadId, payload) {
    const { bannerStore, leadsStore } = this.rootStore;

    const lead = this.findLead(leadId);
    if (!lead) {
      return;
    }

    try {
      const response = await leadsStore.updateLead(lead.id, payload);
      this.saveLead(lead.id, response);
    } catch (err) {
      let message;
      if (err.response) {
        message = Object.values(err.response.data.errors).join(" ");
      } else {
        message = "Please contact support for assistance.";
      }
      bannerStore.addBanner("red", "Failed to update.", message, [], false);
    }
  }

  @action
  updateLeadCustomFieldsAsync = async (leadId, customFieldId, newValue) => {
    const { bannerStore, leadsStore } = this.rootStore;

    try {
      const response = await leadsStore.updateLeadCustomFieldsAsync(
        leadId,
        customFieldId,
        newValue
      );
      this.saveLead(leadId, response);
      return response;
    } catch (err) {
      let message;
      if (err.response) {
        message = Object.values(err.response.data.errors).join(" ");
      } else {
        message =
          "Could not update that custom field. Please contact support for assistance.";
      }

      bannerStore.addBanner("red", "Custom field update failed", message);
    }
  };

  @action
  async getNextPageAsync() {
    if (!this.page.more) {
      return;
    }

    const { leadsStore } = this.rootStore;
    const { filters } = this.rootStore.filtersStore;

    this.setIsLoading(true);

    const filterPayload = {
      ...filters,
      page: this.page.next,
    };

    const response = await leadsStore.getLeads(filterPayload);

    const { data, meta } = response;

    this.setPage(meta.page);

    this.setActivePipelineAmount(this.activePipelineAmount + meta.activePipelineAmount);
    this.setActivePipelineCount(this.activePipelineCount + meta.activePipelineCount);
    this.setTotalPipelineAmount(this.totalPipelineAmount + meta.totalPipelineAmount);
    this.setTotalPipelineCount(this.totalPipelineCount + meta.totalPipelineCount);

    const allLeads = this.leads.concat(data);

    this.setLeads(allLeads);

    this.setIsLoading(false);
  }

  @action
  async updateListLeads(filters, pagesToLoad) {
    const { leadsStore } = this.rootStore;
    let updatedLeads = [];
    const leadsMap = new Map(this.leads.map((lead) => [lead.id, lead]));

    for (let i = 1; i <= pagesToLoad; i++) {
      const filterPayload = {
        ...filters,
        page: i,
      };

      const nextPageResponse = await leadsStore.getLeads(filterPayload);

      const { data } = nextPageResponse;

      updatedLeads.push(...data);
    }

    updatedLeads.forEach((updatedLead) => {
      leadsMap.set(updatedLead.id, updatedLead);
    });

    return Array.from(leadsMap.values());
  }

  @action
  async retrieveLeadsAsync(filters = {}) {
    const { leadsStore } = this.rootStore;
    this.setIsLoading(true);

    try {
      if (!this.isInitialized) {
        this.cancelPreviousRequests();

        const response = await leadsStore.getLeads(filters, { signal: this.abortController.signal });

        const { data, meta } = response;
        const {
          activePipelineAmount,
          activePipelineCount,
          activePipelineTotalAmount,
          activePipelineTotalCount,
          page,
          totalPipelineAmount,
          totalPipelineCount,
          totalPipelineTotalAmount,
          totalPipelineTotalCount,
        } = meta;

        this.setPage(page);
        this.setLeads(data);
        this.setActivePipelineAmount(activePipelineAmount);
        this.setActivePipelineCount(activePipelineCount);
        this.setActivePipelineTotalAmount(activePipelineTotalAmount);
        this.setActivePipelineTotalCount(activePipelineTotalCount);
        this.setTotalPipelineAmount(totalPipelineAmount);
        this.setTotalPipelineCount(totalPipelineCount);
        this.setTotalPipelineTotalAmount(totalPipelineTotalAmount);
        this.setTotalPipelineTotalCount(totalPipelineTotalCount);
        this.setIsLoading(false);
        this.setIsInitialized(true);
      } else {
        const { current } = this.page;
        const pagesToLoad = current > 1 ? 2 : 1;

        const updatedLeads = await this.updateListLeads(filters, pagesToLoad);

        this.setLeads(updatedLeads);
        this.setIsLoading(false);
      }
    } catch (err) {
      this.setIsLoading(false);
      this.setIsInitialized(true);
    }
  }
}

export default ListLeadsStore;
