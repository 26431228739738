import React from "react";
import { PropTypes } from "prop-types";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Breadcrumb, Text } from "@wingmate/toolkit";
import { Link } from "react-router-dom";
import "./RouteBreadcrumbs.scss";

export function RouteBreadcrumbs(props) {
  const breadcrumbs = useBreadcrumbs(props.routes, props.options);
  return (
    <Breadcrumb
      className="RouteBreadcrumbs"
      items={breadcrumbs.map(({ breadcrumb, match }) => ({
        title: (
          <Text type="H6" key={match.pathname}>
            <Link to={match.pathname}>{breadcrumb}</Link>
          </Text>
        )
      }))}
    />
  );
}

RouteBreadcrumbs.propTypes = {
  options: PropTypes.object,
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default RouteBreadcrumbs;
