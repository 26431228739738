export function getErrorMessage(error, defaultErrorMessage) {
  let errorMessage = null;

  if (error?.response?.data?.errors) {
    errorMessage = Object.values(error.response.data.errors).join(" ");
  }

  return errorMessage || defaultErrorMessage;
}

export function parseErrorMessage(err, defaultErrorMessage) {
  const response = err.response || {};
  const data = response.data || {};

  if (data.message) {
    return data.message;
  } else {
    return defaultErrorMessage;
  }
}
