import { action, computed, makeAutoObservable } from "mobx";

const PIN_STORAGE_KEY = "navigationPinned";

interface IRootStore {
  context: {
    currentUser: { id: string | number },
  }
}

export class NavigationStore {
  rootStore;

  isExpanded = false;

  isPinned = false;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;
  }

  @computed get storageKeyPrefix() {
    const { context } = this.rootStore;

    return `NavigationStore/u${context?.currentUser?.id}`;
  }

  @action
  loadNavigationStatus() {
    const isPinnedString = this.getFromLocalStorage(PIN_STORAGE_KEY);

    const isPinned = isPinnedString ? JSON.parse(isPinnedString) : false;

    this.isPinned = isPinned;
  }

  @action
  setIsExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded;
  }

  @action
  setIsPinned(isPinned: boolean) {
    this.isPinned = isPinned;

    this.setToLocalStorage(PIN_STORAGE_KEY, isPinned);
  }

  setToLocalStorage(key: string, value: boolean) {
    localStorage.setItem(
      `${this.storageKeyPrefix}/${key}`,
      JSON.stringify(value),
    );
  }

  getFromLocalStorage(key: string) {
    return localStorage.getItem(`${this.storageKeyPrefix}/${key}`);
  }
}

export default NavigationStore;
