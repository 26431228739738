import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { AppContext } from "../../../../context/AppProvider";
import { rootStorePropTypes } from "../../../../proptypes/stores";
import { LeadStats } from "./LeadStats";

import "../DashboardInfo.scss";

@inject("rootStore")
@observer
class DashboardStats extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    rootStore: rootStorePropTypes,
    t: PropTypes.func,
  };

  getLeadStats = () => {
    const { rootStore } = this.props;
    const { funnelStore, listLeadsStore, mapStore, viewStore } = rootStore;
    const { view } = viewStore;

    if(view.id === "map") {
      return mapStore.leadStats;
    } else if(view.id === "funnel") {
      return funnelStore.leadStats;
    } else {
      return listLeadsStore.leadStats;
    }
  };

  shouldRenderLeadStats = () => {
    const { rootStore } = this.props;
    const { viewStore } = rootStore;
    const { view } = viewStore;

    return view.id === "funnel" || view.id === "list" || view.id === "map" || view.id === "table";
  }

  render() {
    const { rootStore } = this.props;
    const { filtersStore } = rootStore;
    const { filterCount } = filtersStore;

    const whenNoFilters = filterCount === 0;
    return (
      this.shouldRenderLeadStats() && 
      <LeadStats {...this.getLeadStats()} showOnlyTotal={whenNoFilters} />
    );
  }
}

export { DashboardStats };

export default DashboardStats;
