import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { ChevronDownIcon, ChevronUpIcon } from "@wingmate/graphics";
import { Accordion, Flex, Text } from "@wingmate/toolkit";
import { useStore } from "../../../../hooks/useStore";
import { isNullOrUndefined } from "../../../../utils/commons";

import "./NavigationItem.scss";

export interface INavigationItemProps {
  activeIcon?: React.ReactElement;
  children?: React.ReactNode;
  inactiveIcon?: React.ReactElement;
  label: string;
  to: string;
}

export const NavigationItem = observer(({
  activeIcon,
  children,
  inactiveIcon,
  label,
  to,
}: INavigationItemProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { navigationStore } = useStore();
  const { isExpanded, isPinned } = navigationStore;

  const location = useLocation();

  const onCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const iconPresent = !isNullOrUndefined(activeIcon) || !isNullOrUndefined(inactiveIcon);
  const isActive = location.pathname.startsWith(to);

  const showLabel = isPinned || isExpanded;
  const showCollapseIcon = showLabel && !isNullOrUndefined(children);

  const renderExpandIcon = () => (
    isCollapsed ? (
      <ChevronUpIcon className="Link__Chevron" data-testid="ChevronUpIcon" />
    ) : (
      <ChevronDownIcon className="Link__Chevron" data-testid="ChevronDownIcon" />
    )
  );

  return (
    <Accordion
      className="NavigationItem"
      accordionKey="navigation-item"
      content={isCollapsed && <div className="NavigationItem__Children">{children}</div>}
      header={(
        <NavLink
          className="NavigationItem__Link"
          to={to}
        >
          <Flex align="center" justify="space-between" flex="1">
            <Flex align="center" justify="center" gap="large">
              {iconPresent && (
                <div className="Link__Icon">
                  {isActive ? activeIcon : inactiveIcon}
                </div>
              )}
              {showLabel && <Text type="H6">{label}</Text>}
            </Flex>
          </Flex>
        </NavLink>
      )}
      bordered={false}
      ghost
      defaultActiveKey={["navigation-item"]}
      collapsible={showCollapseIcon ? "icon" : "disabled"}
      expandIcon={renderExpandIcon}
      expandIconPosition="end"
      onClick={onCollapse}
      showArrow={showCollapseIcon}
    />
  );
});

export default NavigationItem;
