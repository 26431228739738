import React, { useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import PropTypes from "prop-types";
import getTestIDs from "../../utils/getTestIDs";
import "./Flyout.scss";

export const testIDs = getTestIDs();

export const Flyout = function (props) {
  const {
    children,
    offSetX,
    offSetY,
    placement,
    placementRef,
    portal,
    showArrow,
  } = props;
  const [popperRef, setPopperRef] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const modifiers = [
    { name: "offset", options: { offset: [offSetX, offSetY] } },
  ];

  if (showArrow) {
    modifiers.push({ name: "arrow", options: { element: arrowElement } });
  }

  const { attributes, styles } = usePopper(placementRef, popperRef, {
    placement,
    modifiers,
  });

  function renderFlyout() {
    return (
      <div
        className="Flyout__popper"
        ref={setPopperRef}
        style={styles.popper}
        {...attributes.popper}
        data-testid="flyout"
      >
        {children}
        {showArrow && (
          <div
            className="Flyout__arrow"
            ref={setArrowElement}
            style={styles.arrow}
          />
        )}
      </div>
    );
  }

  if (portal) {
    return <>{ReactDOM.createPortal(renderFlyout(), document.body)}</>;
  } else {
    return renderFlyout();
  }
};

Flyout.defaultProps = {
  placement: "top",
  offSetX: 0,
  offSetY: 0,
  portal: false,
  showArrow: true,
};

Flyout.propTypes = {
  children: PropTypes.node,
  offSetX: PropTypes.number,
  offSetY: PropTypes.number,
  placement: PropTypes.string,
  placementRef: PropTypes.object,
  portal: PropTypes.bool,
  showArrow: PropTypes.bool,
};

export default Flyout;
