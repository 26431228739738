import React from "react";
import { inject, observer } from "mobx-react";
import getTestIDs from "../../utils/getTestIDs";
import { Banner } from "./Banner/Banner";
import { bannerStorePropTypes } from "../../proptypes/stores";
import "./BannerColumn.scss";

export const testIDs = getTestIDs();

@inject("bannerStore")
@observer
class BannerColumn extends React.Component {
  static propTypes = {
    bannerStore: bannerStorePropTypes,
  };

  renderBanners = () => {
    const { bannerStore } = this.props;

    return bannerStore.banners.map((banner, index) => (
      <Banner key={banner.id} index={index} {...banner} />
    ));
  };

  render() {
    return (
      <div className="BannerColumn__component" data-testid="banner-column">
        {this.renderBanners()}
      </div>
    );
  }
}

export { BannerColumn };
