import React from "react";
import { PropTypes } from "prop-types";
import { Button, FlexCol, Text } from "wm-ui-toolkit";
import { ReactComponent as WmLogoImage } from "../../assets/images/wingmate-icon.svg";
import { withTranslations } from "../../utils/withTranslations";
import { Link } from "react-router-dom";

import "./ErrorPage.scss";

@withTranslations
export class ErrorPage extends React.Component {
  static propTypes = {
    errorCode: PropTypes.string,
    t: PropTypes.func,
  };

  render() {
    const { errorCode, t } = this.props;

    return (
      <FlexCol className="ErrorPage" alignItems="center" gap="XS">
        <Text type="H3" weight="Bold">
          {t(`${errorCode ?? "general"}.title`)}
        </Text>
        <Text type="H4">{t(`${errorCode ?? "general"}.description`)}</Text>
        <WmLogoImage width="280px" height="200px" />
        <Link type="text" to="/">
          <Button type="primary">
            <Text>{t("home")}</Text>
          </Button>
        </Link>
        <Link type="text" to="https://support.wingmateapp.com/" target="_blank">
          <Text>{t("support")}</Text>
        </Link>
      </FlexCol>
    );
  }
}

export default ErrorPage;
