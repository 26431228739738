import React, { useContext } from "react";
import { Text, Flex } from "@wingmate/toolkit";
import { useTranslation } from "react-i18next";
import pluralize from "pluralize";
import { AppContext } from "../../../../context";
import { formatAndShortenMoney } from "../../../../utils/commons";

import "./LeadStatsOption.scss";

interface ILeadStatsOptionProps {
  filteredValue?: number;
  totalValue: number;
  filterNumberOfLeads?: number;
  totalNumberOfLeads: number;
  option: "totalPipeline" | "activePipeline";
  filtered: boolean;
}

export const LeadStatsOption: React.FC<ILeadStatsOptionProps> = ({
  filterNumberOfLeads,
  filtered,
  filteredValue,
  option,
  totalNumberOfLeads,
  totalValue,
}) => {
  const { t } = useTranslation(["default", "common"]);
  const { campaign } = useContext(AppContext);
  const { leadLabel } = campaign;

  const safeFilteredValue = formatAndShortenMoney(filteredValue);
  const safeTotalValue = formatAndShortenMoney(totalValue);
  const safeFilterNumberOfLeads = filterNumberOfLeads;
  const safeTotalNumberOfLeads = totalNumberOfLeads;

  const valueString = filtered ? `${safeFilteredValue} / ${safeTotalValue}` : `${safeTotalValue}`;
  const leadString = filtered ? `${safeFilterNumberOfLeads} / ${safeTotalNumberOfLeads}` : `${safeTotalNumberOfLeads}`;

  return (
    <Flex className="LeadStatsOption">
      <Flex className="LeadStatsOption__Value" gap={4}>
        <Text weight="Medium" type="P">
          {t(option)}
        </Text>
        <Text type="P">
          {valueString}
        </Text>
      </Flex>
      <Text className="LeadStatsOption__Total" type="P">
        {safeTotalValue}
      </Text>
      <div className="LeadStatsOption__Divider" />
      <Flex className="LeadStatsOption__Count" gap={4}>
        <Text weight="Medium" type="P">
          {t(`${option}Leads`, { leadLabel: pluralize(leadLabel) })}
        </Text>
        <Text type="P">
          {leadString}
        </Text>
      </Flex>
      <Text className="LeadStatsOption__Total" type="P">
        {safeTotalNumberOfLeads}
      </Text>
    </Flex>
  );
};

export default LeadStatsOption;
