import React from "react";
import PropTypes from "prop-types";
import { theme } from "@wingmate/toolkit";
import { ConfigProvider } from "antd";

const StyleProvider = ({ children }) => {
  return <ConfigProvider theme={theme}>{children}</ConfigProvider>;
};

StyleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StyleProvider;
