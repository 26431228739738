import { action, computed, makeObservable, observable } from "mobx";
import * as leadsService from "../requests/leads";
import * as campaignReminderService from "../requests/campaigns/reminders";

export class PipelineStore {
  rootStore;

  @observable activeLeads;

  @observable activeLeadsCount;

  @observable overdueReminders;

  @observable overdueRemindersCount;

  @observable flaggedLeads;

  @observable flaggedLeadsCount;

  @observable isLeadLoading;

  @observable isReminderLoading;

  @observable isActiveLeadLoading;

  @observable initialLoad;

  @observable hasMoreLeads;

  @observable hasMoreReminders;

  @observable hasMoreActiveLeads;

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.overdueReminders = [];
    this.remindersPage = {};
    this.activeLeadsPage = {};
    this.flaggedLeads = [];
    this.activeLeads = [];
    this.leadsPage = {};
    this.flaggedLeadsCount = 0;
    this.overdueRemindersCount = 0;
    this.activeLeadsCount = 0;

    this.initialLoad = true;
    this.isReminderLoading = true;
    this.isLeadLoading = true;
    this.hasMoreLeads = false;
    this.hasMoreReminders = false;
    this.error = null;
  }

  @action
  setIsLeadLoading(loading) {
    this.isLeadLoading = loading;
  }

  @action
  setIsReminderLoading(loading) {
    this.isReminderLoading = loading;
  }

  @action
  setIsActiveLeadLoading(loading) {
    this.isActiveLeadLoading = loading;
  }

  @action
  setInitialLoad(loading) {
    this.initialLoad = loading;
  }

  @computed get isLoading() {
    return this.isLeadLoading || this.isReminderLoading;
  }

  @action
  setError(message) {
    this.error = message;
  }

  @action
  async initializeDashboard(userId, campaignId) {
    this.userId = userId;
    this.campaignId = campaignId;

    await Promise.all([
      this.setRemindersAsync(),
      this.setFlaggedLeadAsync(),
      this.setActiveLeadsAsync(),
    ]);
    this.setInitialLoad(false);
  }

  @action
  async setRemindersAsync(filters = {}, initialLoad = true) {
    const overdueFilters = Object.assign(filters, {
      status: "overdue",
      pm_account: true,
      sort_order: "asc",
      sort_by: "date",
    });

    try {
      const response = await campaignReminderService.getReminders(
        this.campaignId,
        overdueFilters
      );

      const { data, meta } = response.data;
      const { count, page } = meta;
      const reminders = data;

      if (initialLoad) {
        this.setOverdueReminders(reminders);
        this.setOverdueRemindersCount(count);
        this.remindersPage = page;

        this.setHasMoreReminders(page.more);

        this.setIsReminderLoading(false);
        return {};
      }

      const newReminders = {
        reminders,
        count,
        page,
      };

      if (!page.more) {
        this.setHasMoreReminders(false);
      }

      return newReminders;
    } catch (err) {
      return [];
    }
  }

  @action
  setHasMoreReminders(hasMoreReminders) {
    this.hasMoreReminders = hasMoreReminders;
  }

  @action
  setOverdueReminders(overdueReminders) {
    this.overdueReminders = overdueReminders;
  }

  @action
  setOverdueRemindersCount(overdueRemindersCount) {
    this.overdueRemindersCount = overdueRemindersCount;
  }

  @action
  async getOverdueNextPage() {
    if (!this.remindersPage.more) {
      return;
    }

    this.setIsReminderLoading(true);

    const nextReminderResponse = await this.setRemindersAsync(
      {
        page: this.remindersPage.next,
      },
      false
    );

    this.setOverdueReminders(
      this.overdueReminders.concat(nextReminderResponse.reminders)
    );
    this.remindersPage = nextReminderResponse.page;

    this.setIsReminderLoading(false);
  }

  @action
  async getFlaggedNextPage() {
    if (!this.leadsPage.more) {
      return;
    }

    this.setIsLeadLoading(true);

    const nextPageResponse = await this.setFlaggedLeadAsync(
      {
        page: this.leadsPage.next,
      },
      false
    );

    this.setFlaggedLeads(this.flaggedLeads.concat(nextPageResponse.leads));
    this.leadsPage = nextPageResponse.page;

    this.setIsLeadLoading(false);
  }

  @action
  async getActiveNextPage() {
    if (!this.activeLeadsPage.more) {
      return;
    }

    this.setIsActiveLeadLoading(true);

    const nextPageResponse = await this.setActiveLeadsAsync(
      {
        page: this.activeLeadsPage.next,
      },
      false
    );

    this.setActiveLeads(this.activeLeads.concat(nextPageResponse.leads));
    this.activeLeadsPage = nextPageResponse.page;

    this.setIsActiveLeadLoading(false);
  }

  @action
  setFlaggedLeads(flaggedLeads) {
    this.flaggedLeads = flaggedLeads;
  }

  @action
  setFlaggedLeadsCount(flaggedLeadsCount) {
    this.flaggedLeadsCount = flaggedLeadsCount;
  }

  @action
  setHasMoreFlaggedLeads(hasMoreLeads) {
    this.hasMoreLeads = hasMoreLeads;
  }

  @action
  setActiveLeads(activeLeads) {
    this.activeLeads = activeLeads;
  }

  @action
  setActiveLeadsCount(activeLeadsCount) {
    this.activeLeadsCount = activeLeadsCount;
  }

  @action
  setHasMoreActiveLeads(hasMoreLeads) {
    this.hasMoreActiveLeads = hasMoreLeads;
  }

  @action
  async setActiveLeadsAsync(filters = {}, initialLoad = true) {
    const flaggedFilters = Object.assign(filters, {
      track_status: "active",
      order_by: "actioned_at_asc",
    });

    try {
      const { data, meta } = await leadsService.getLeads(flaggedFilters);

      const { count, page } = meta;
      const leads = data;

      if (initialLoad) {
        this.setActiveLeads(leads);
        this.setActiveLeadsCount(count);
        this.activeLeadsPage = page;
        this.setHasMoreActiveLeads(page.more);

        this.setIsActiveLeadLoading(false);
        return {};
      }
      const newLeads = {
        leads,
        count,
        page,
      };

      if (!page.more) {
        this.setHasMoreActiveLeads(false);
      }

      return newLeads;
    } catch (err) {
      return [];
    }
  }

  @action
  async setFlaggedLeadAsync(filters = {}, initialLoad = true) {
    const flaggedFilters = Object.assign(filters, {
      flagged: true,
      order_by: "actioned_at_asc",
    });

    try {
      const { data, meta } = await leadsService.getLeads(flaggedFilters);

      const { count, page } = meta;
      const leads = data;

      if (initialLoad) {
        this.setFlaggedLeads(leads);
        this.setFlaggedLeadsCount(count);
        this.leadsPage = page;
        this.setHasMoreFlaggedLeads(page.more);

        this.setIsLeadLoading(false);
        return {};
      }
      const newLeads = {
        leads,
        count,
        page,
      };

      if (!page.more) {
        this.setHasMoreFlaggedLeads(false);
      }

      return newLeads;
    } catch (err) {
      return [];
    }
  }
}

export default PipelineStore;
