import moment from "moment";
import * as naics from "../assets/naics/en.json";
import * as sic from "../assets/sic/en.json";

const SHORTEN_MONEY_THRESHOLD = 1000;

export function buildParams (data) {
  const params = new URLSearchParams();

  Object.keys(data).map((key) => {
    const value = data[key];

    if (Array.isArray(data[key])) {
      const arrayKey = `${key}[]`;
      value.map((item) => params.append(arrayKey, item));
    } else {
      params.append(key, value);
    }
    return key;
  });

  return params.toString();
}

export function calculateRoundedPercentage(originalPrice, discountedPrice) {
  if (discountedPrice === 0) return 100;

  if (originalPrice === discountedPrice) return 0;

  const rawPercentage =
    ((originalPrice - discountedPrice) / originalPrice) * 100;

  const roundedPercentage = Math.round(rawPercentage);

  const finalPercentage = Math.min(Math.max(roundedPercentage, 1), 99);

  return finalPercentage;
}

export function leadIdentifier(campaign, leadId) {
  if (campaign && campaign.leadLabel) {
    return `${campaign.leadLabel} #${leadId}`;
  }
  return `Lead #${leadId}`;
}

export function getBaseURL() {
  return window.href;
}

export function getURL(uri) {
  return `${getBaseURL()}${uri}`;
}

export function flatten(str) {
  return str.toLowerCase().split(" ").join("");
}

export function formatMoney(number) {
  // from https://stackoverflow.com/a/14428340/1001980

  try {
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } catch {
    return "0.00";
  }
}

export function formatAndShortenMoney(number) {
  try {
    return number <= SHORTEN_MONEY_THRESHOLD
      ? number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
      : Intl.NumberFormat("en", {
          notation: "compact",
          maximumFractionDigits: 1,
        }).format(number);
  } catch {
    return "0.00";
  }
}

export function removeItemFromArray(arr, value) {
  let i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      i += 1;
    }
  }
  return arr;
}

export function searchItems(items, searchTerm) {
  if (Array.isArray(items)) {
    return items.filter((item) =>
      item.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
  return null;
}

export function sameElements(array1, array2) {
  if (array1.length === array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true;
      }
      return false;
    });
  }
  return false;
}

export function formatErrorMessage(errorList) {
  let errorMessage = errorList.join(". ");

  if (errorMessage) {
    errorMessage = errorMessage + ".";
  }

  return errorMessage;
}

export function getUsername(user) {
  let username;

  if (user) username = user.fullName;

  return username;
}

export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}

export function isNullOrEmpty(value) {
  return (
    value === "" ||
    value === null ||
    value === undefined ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === "object" && Object.keys(value).length === 0)
  );
}

export function removeDuplicates(array) {
  return Array.from(new Set(array));
}

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function sortByAscDate(dateA, dateB) {
  if (moment(dateA) < moment(dateB)) {
    return -1;
  } else {
    return 1;
  }
}

export function sortByDescDate(dateA, dateB) {
  if (moment(dateA) > moment(dateB)) {
    return -1;
  } else {
    return 1;
  }
}

export function underScoreToCamelCase(underScoreString) {
  return underScoreString.replace(/_([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}

export function createNewFormDataRequest(name, object) {
  const formData = new FormData();
  Object.keys(object).map((key) => {
    if (Array.isArray(object[key])) {
      const formDataKey = `${name}[${key}]`;
      formData.append(formDataKey, object[key].join(","));
    } else {
      const formDataKey = `${name}[${key}]`;
      formData.append(formDataKey, object[key]);
    }
  });
  return formData;
}

export function deepCopy(object) {
  if (!object) return object;
  return JSON.parse(JSON.stringify(object));
}

export function formatDateCustomField(value) {
  return moment(value, ["MMMM D, YYYY", moment.ISO_8601]).format();
}

export function formatDateTimeCustomField(value) {
  return moment(value, ["MMMM D, YYYY h:mm A", moment.ISO_8601]).format();
}

export function formatCustomFieldValue(type, value, version) {
  if (value) {
    if (type === "date") {
      return new Date(formatDateCustomField(value));
    }
    if (type === "datetime") {
      return new Date(formatDateTimeCustomField(value));
    }
    if (type === "sic" && value) {
      return `${value} : ${sic.default.en.sic[version][value]}`;
    } else if (type === "naics" && value) {
      return `${value} : ${naics.default.en.naics[version][value]}`;
    }
  }
  return value;
}
