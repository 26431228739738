import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { TranslationProvider } from "../providers/TranslationProvider";
import { storePropTypes } from "../proptypes/stores";
import { AppContext } from "../context/AppProvider";
import { withRouter } from "../utils/withRouter";
import i18next from "../pages/Analytics/translations/i18n";

@withRouter
export class AnalyticsRoute extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    store: storePropTypes,
  };

  render() {
    const { currentUser } = this.context;

    return (
      <TranslationProvider i18n={i18next}>
        <div data-testid="AnalyticsRoute">
          {currentUser && currentUser.type === "Manager" ? (
            <Outlet />
          ) : (
            <Navigate to="/" />
          )}
        </div>
      </TranslationProvider>
    );
  }
}
