import React from "react";
import PropTypes from "prop-types";
import { Flex, Text } from "@wingmate/toolkit";
import { WmLogoIcon } from "wm-graphics";
import { inject, observer } from "mobx-react";
import { withRouter } from "../../../utils/withRouter";
import { AppContext } from "../../../context/AppProvider";
import { rootStorePropTypes } from "../../../proptypes/stores";
import "./HomeButton.scss";

@withRouter
@inject("rootStore")
@observer
export class HomeButton extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
  }

  static propTypes = {
    location: PropTypes.object,
    navigate: PropTypes.func,
    rootStore: rootStorePropTypes,
    route: PropTypes.string,
  };

  onClick = () => {
    const { location, navigate, rootStore } = this.props;
    const { currentUser } = this.context;
    const { viewStore } = rootStore;
    const { pathname } = location;

    if (currentUser.type === "Worker") {
      navigate("/frontline");
    } else {
      if (pathname === "/dashboard") {
        viewStore.setView("funnel");
      }
      navigate("/dashboard");
    }
  };

  render() {
    const { campaign } = this.context;
    const { logoUrl } = campaign;
    const { rootStore } = this.props;
    const { navigationStore } = rootStore;
    const { isExpanded, isPinned } = navigationStore;

    const showCompanyName = isExpanded || isPinned;

    return (
      <Flex className="HomeButton" gap="small" vertical justify="center" align="flex-start">
        <div
          className="HomeButton__CampaignInfo"
          data-testid="HomeButton"
          onClick={this.onClick}
          onKeyDown={this.onClick}
          role="button"
          tabIndex={0}
        >
          <Flex className="CampaignInfo__Container" align="center" gap="middle">
            {logoUrl ? (
              <img className="Container__Logo" src={logoUrl} alt="Wingmate" />
            ) : (
              <WmLogoIcon className="Container__Logo" />
            )}
            {showCompanyName && <Text type="H6" weight="500">{campaign.name}</Text>}
          </Flex>
        </div>
      </Flex>
    );
  }
}

export default HomeButton;
