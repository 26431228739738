import { mapsEndpoint } from "../../utils/endpoint";
import * as axiosService from "../axiosRequests";

const TERRITORIES_ENDPOINT = (campaignId) =>
  mapsEndpoint(`v1/campaigns/${campaignId}/territories`);

const LEADS_TERRITORIES_ENDPOINT = (campaignId, leadId) =>
  mapsEndpoint(`v1/campaigns/${campaignId}/leads/${leadId}/territories`);

export async function getTerritories(campaignId, user, latitude, longitude) {
  const headers = {
    Authorization: `Bearer ${user.authToken}`,
  };

  let params = {};

  // Add latitude and longitude to params if they exist
  if (latitude !== undefined && longitude !== undefined) {
    params = { ...params, latitude, longitude };
  }

  const response = await axiosService.axiosGet(
    TERRITORIES_ENDPOINT(campaignId),
    params,
    headers
  );
  return response.data;
}

export async function postTerritory(campaignId, user, payload) {
  const headers = {
    Authorization: `Bearer ${user.authToken}`,
  };

  const response = await axiosService.axiosPost(
    TERRITORIES_ENDPOINT(campaignId),
    payload,
    headers
  );
  return response.data;
}

export async function updateTerritory(campaignId, user, payload) {
  const headers = {
    Authorization: `Bearer ${user.authToken}`,
  };

  const response = await axiosService.axiosPut(
    TERRITORIES_ENDPOINT(campaignId),
    payload,
    headers
  );
  return response.data;
}

export async function deleteTerritory(campaignId, user, payload) {
  const headers = {
    Authorization: `Bearer ${user.authToken}`,
  };

  const response = await axiosService.axiosDelete(
    TERRITORIES_ENDPOINT(campaignId),
    payload,
    headers
  );
  return response.data;
}

export async function getLeadsTerritories(campaignId, leadId, user) {
  const headers = {
    Authorization: `Bearer ${user.authToken}`,
  };

  const response = await axiosService.axiosGet(
    LEADS_TERRITORIES_ENDPOINT(campaignId, leadId),
    {},
    headers
  );

  return response.data;
}
