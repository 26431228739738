import React, { useContext, useEffect } from "react";
import moment from "moment";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  Button, Dropdown, DropdownMenuItemProps, Flex, Text,
} from "@wingmate/toolkit";
import { AppContext } from "../../../context/AppProvider";
import { Username } from "../../../common/Users/Username/Username";
import { useStore } from "../../../hooks/useStore";
import { ContractSelector } from "../ContractSelector/ContractSelector";
import { CurrentScoreCard } from "../CurrentScoreCard/CurrentScoreCard";
import { DashboardStats } from "../../../pages/Dashboard/DashboardInfo/LeadStats/DashboardStats";
import { Notifications } from "../Notifications/Notifications";

import "./TopNavigation.scss";

const WORKER_TYPE = "Worker";

export const TopNavigation = observer(() => {
  const { t } = useTranslation(["default", "common"]);
  const { campaign, currentUser } = useContext(AppContext);
  const { name, scEnabled } = campaign;
  const {
    authStore,
    contractStore,
    navigationStore,
  } = useStore();
  const { contracts, isInitialized } = contractStore;
  const { isPinned } = navigationStore;

  useEffect(() => {
    const getUserContracts = async () => {
      await contractStore.getUserContractsAsync();
    };

    getUserContracts();
  }, [contractStore]);

  const logout = async () => {
    await authStore.logoutUser();
  };

  const showScoreCard = currentUser.type !== WORKER_TYPE && scEnabled;

  const userDropdownItems: DropdownMenuItemProps = [
    {
      key: "profile",
      label: (
        <Link to={`/users/${currentUser.id}/edit`}>
          {t("common:layouts.actions.profile")}
        </Link>
      ),
    },
    {
      key: "notifications",
      label: (
        <Link to="/notifications">
          {t("common:layouts.actions.notifications")}
        </Link>
      ),
    },
    {
      key: "trash",
      label: (
        <Link to="/trash">
          {t("common:layouts.actions.trash")}
        </Link>
      ),
    },
    {
      key: "support",
      label: (
        <a href="https://support.wingmateapp.com" target="_blank" rel="noopener noreferrer">
          {t("common:navigation.sideNavigation.support")}
        </a>
      ),
    },
    {
      key: "logout",
      label: t("common:layouts.actions.logout"),
      onClick: logout,
    },
  ];

  const renderUserProfileDropdown = () => (
    <Dropdown rootClassName="TopNavigation__UserDropdown" menu={{ items: userDropdownItems }} trigger={["click"]}>
      <Button className="RightSection__UserSection" type="text">
        <Flex align="center" gap="middle">
          <Username user={currentUser} hideName />
          <Flex className="UserSection__Info" gap={4} align="flex-start" vertical>
            <Text type="P" weight="Medium">{currentUser.fullName}</Text>
            <Text type="S">
              {`${t("common:memberSince", {
                date: moment(currentUser.createdAt).format("MMM D, YYYY"),
              })} `}
            </Text>
          </Flex>
        </Flex>
      </Button>
    </Dropdown>
  );

  const contractsLoaded = contracts.length > 0 && isInitialized;

  const topNavigationClassNames = classNames("TopNavigation", { sidebarOpen: isPinned });

  return (
    <Flex className={topNavigationClassNames} align="center" justify="space-between" gap={16}>
      <Flex className="TopNavigation__LeftSection" align="center" gap="large">
        {contractsLoaded && <ContractSelector contracts={contracts} />}
        <Flex vertical>
          <Text className="LeftSection__CampaignName" type="H6" weight="Medium">{name}</Text>
          <DashboardStats />
        </Flex>
      </Flex>
      <div className="TopNavigation__Separator" />
      <Flex className="TopNavigation__RightSection" align="center" gap="middle">
        <Flex className="RightSection__actions" align="center" gap="large">
          {showScoreCard && (
            <CurrentScoreCard />
          )}
          <Notifications />
        </Flex>
        <div className="RightSection__Separator" />
        {renderUserProfileDropdown()}
      </Flex>
    </Flex>
  );
});

export default TopNavigation;
